<template>
	<a-spin :spinning="spinning" tip="登录中..." size="large">
		<a-layout class="ui-main">
			<a-layout-content class="ui-layoutContent">
				<!-- <img class="ui-logo" src="@/assets/image/logo.png" /> -->
				<div class="ui-box">
					<img class="ui-logo2" src="@/assets/image/logo.png" />
					<div class="ui-project">
						<div class="ui-project__title">上栗县行政事业性国有资产</div>
						<div class="ui-project__title">台账管理系统</div>
					</div>

					<div class="ui-form">
						<a-form :model="formState" name="basic" autocomplete="off" :wrapperCol="{ span: 24 }"
							@finish="onFinish">
							<a-form-item class="ui-form-item" name="username" :rules="[{ required: true, message: '请输入登录账号!' }]">
								<a-input size="large" :bordered="false" v-model:value="formState.username"
									placeholder="请输入登录账号">
									<template #prefix>
										<img class="ui-icon" src="@/assets/image/login/login-number.png" />
									</template>
								</a-input>
							</a-form-item>

							<a-form-item class="ui-form-item" name="password" :rules="[{ required: true, message: '请输入登录密码!' }]">
								<a-input-password v-model:value="formState.password" :bordered="false" size="large"
									placeholder="请输入密码">
									<template #prefix>
										<img class="ui-icon" src="@/assets/image/login/login-password.png" />
									</template>
								</a-input-password>
							</a-form-item>


							<a-checkbox v-model:checked="remember">记住登录账号</a-checkbox>


							<a-form-item>
								<a-button class="ui-submit" block type="primary" html-type="submit">登录</a-button>
							</a-form-item>
						</a-form>
					</div>
				</div>
				<!-- <a-layout-footer class="ui-footer"> Copyright 2022 yb </a-layout-footer> -->
			</a-layout-content>
		</a-layout>
		<update ref="updateRef" :model="formState"></update>
	</a-spin>
</template>

<script>
	import update from './component/update.vue';
	import {
		login,
		getPower
	} from '@/service/modules/system';
	import {
		initRouter
	} from "@/utils/routerUtil";
	import {
		Icon
	} from "@/components/icon/icon.js";
	export default {
		components: {
			Icon,update
		},
		data() {
			return {
				spinning: false,
				formState: {
					username: "",
					password: "",
				},
				remember: false,
				updatePassword: false
			};
		},
		created() {
			console.log(1111111);
			const formModel = this.$getLS('rememberLoginInfo');
			if (formModel) {
				this.remember = true;
				this.formState = formModel;
			}
		},
		// mounted(){
		// 	this.$refs.updateRef.open();
		// },
		methods: {
			onFinish(values) {
				if (this.remember) {
					this.$setLS('rememberLoginInfo', values);
				} else {
					this.$rmLS('rememberLoginInfo');
				}
				this.spinning = true;
				login(values).then(res => {
					if (res.code == 200) {
						getPower({}).then(menuData => {
							this.spinning = false;
							if (menuData.code == 200) {
								let permissionList = menuData.data.powerApis;
								// values.name = values.username;
								values.name = res.data.name;
								values.dept = res.data.dept;
								values.mangerList = res.data.mangerList;
								values.unitName = res.data.unitName;
								values.unitId = res.data.unitId;
								values.adjust = res.data.adjust;
								this.$store.commit("system/setLogin", true);
								// this.$store.commit("system/setUserInfo", values);
								this.$store.commit("system/setUserInfo", values);
								this.$store.commit("system/set_menuList", menuData.data.adminMenus);
								this.$store.commit("count/setMangerList",values.mangerList);
								// 设置权限Code列表
								this.$store.commit("system/setPermission", permissionList);
								// 加载有权限的路由
								initRouter();
								if (permissionList.includes('pc_jobs')) {
									this.$router.replace("/");
								} else {
									this.$router.replace("/index/welcome");
								}
							}
						})
					} else if (res.code == 1008){
						this.spinning = false;
						this.formState = {};
						this.$refs.updateRef.open();
					} else {
						this.spinning = false;
					}
				}).catch(() => {
					this.spinning = false;
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.ui-main {
		width: 100vw;
		min-height: 100vh;
		// padding: 0px 0 30px;
		text-align: center;
		//min-width: 920px;
		// background: #f0f2f5 url("../../assets/image/login/login-bg.png");
		background: url("../../assets/image/login/login-bg2.png") -180px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.ui-logo {
		width: 100px;
		// height: 49px;
		position: fixed;
		top: 40px;
		right: 40px;
	}
	.ui-logo2 {
		width: 80px;
		height: 80px;
		margin-bottom: 20px;
	}
	.ui-box {
		width: 410px;
		height: 100vh;
		padding: 200px 35px;
		background: #FFF;
		position: absolute;
		right: 0;
		// top: 0;
		// border-radius: 20px;
		// position: absolute;
		// margin-top: 25%;
		// margin-left: auto;
		// margin-right: 18.5%;
	}

	.ui-project {
		text-align: center;
		// display: flex;
		// align-items: center;
		// justify-content: center;

		.ui-project__logo {
			width: 50px;
			height: 50px;
			margin-right: 10px;
		}

		.ui-project__title {
			font-size: 24px;
			font-weight: 600;
			color: #333333;
			line-height: 36px;
		}
	}

	.ui-desc {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.45);
		margin-top: 12px;
		margin-bottom: 40px;
	}

	.ui-form {
		// width: 400px;
		margin-top: 40px;
		text-align: left;

		.ui-form-item {
			border-bottom: 1px solid #E5E7F1;
		}

		.ui-icon {
			width: 16px;
			height: 16px;
		}

		.ui-submit {
			height: 44px;
			margin-top: 30px;
			border-radius: 50px;
		}
	}

	.ui-footer {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.45);
	}

	@media screen and (max-width: 768px) {
		.ui-form {
			width: 88%;
		}
	}
	.ui-layoutContent {
		// display: flex;
	}
</style>
