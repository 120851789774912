<template>
    <div>
        <a-modal v-model:visible="visible" width="400px">
            <template #title>
				<modalHeader title="修改密码"></modalHeader>
			</template>
            <template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">确认修改</a-button>
			</template>
            <a-spin :spinning="loading">
                <a-form ref="formRef" :model="formModal" :label-col="{ span: 9 }">
                    <a-form-item label="账号" name="username" :rules="[{required:true,message:'请填写账号'}]" :label-col="{ span: 5 }">
                        <!-- <a-input v-model:value="formModal.username"></a-input> -->
                        <a-input size="large" :bordered="false" v-model:value="formModal.username"
                            placeholder="请输入登录手机号">
                        </a-input>
                    </a-form-item>
                    <a-form-item label="旧密码" name="oldPassword" :rules="[{required:true,message:'请填写旧密码'}]" :label-col="{ span: 5 }">
                        <!-- <a-input v-model:value="formModal.oldPassword"></a-input> -->
                        <a-input-password v-model:value="formModal.oldPassword" :bordered="false" size="large"
                            placeholder="请输入旧密码">
                        </a-input-password>
                    </a-form-item>
                    <a-form-item label="新密码" name="newPassword" :rules="[{required:true,message:'请填写新密码'}]" :label-col="{ span: 5 }">
                        <!-- <a-input v-model:value="formModal.newPassword"></a-input> -->
                        <a-input-password v-model:value="formModal.newPassword" :bordered="false" size="large"
                            placeholder="请输入新密码">
                        </a-input-password>
                    </a-form-item>
                </a-form>
            </a-spin>

        </a-modal>
    </div>
</template>

<script>
import modalHeader from '@/components/modalHeader/index.vue';
import {postUpdatePassword} from '@/service/modules/system';
export default {
    components:{modalHeader},
    data(){
        return {
            visible:false,
            formModal:{},
            loading:false
        }
    },
    props:{
        model:{
            type:Object,
            default:{}
        }
    },
    methods:{
        open(){
            this.formModal = this.model;
            this.visible = true;
        },
        async onSubmit(){
            if(!this.formModal.username){
                this.$message.warn('账号不得为空');
                return;
            }
            if(this.formModal.newPassword == this.formModal.oldPassword){
                this.$message.warn('新旧密码不得一致');
                return;
            }
            let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
            let flag = reg.test(this.formModal.newPassword);
            if(!flag){
                this.$message.warn('密码必须包括大写，小写和数字，请重新输入');
                this.formModal.newPassword = '';
                return;
            }
            let res = await postUpdatePassword(this.formModal);
            this.loading = true;
            if(res.code == 200){
                this.$message.success('密码修改成功，请重新登录');
                this.loading = false;
                this.visible = false;
            }else{
                this.loading = false;
            }
        },
        close(){
            this.visible = false;
        }
    }
}
</script>

<style scoped>

</style>
